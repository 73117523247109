<template>
  <div class="w-full mt-1">
    <b-form-group
      v-slot="{ ariaDescribedby }"
      llabel-cols-sm="3"
      label-align-sm="left"
      label-size="sm"
      label="Include In Table"
    >
      <b-form-checkbox-group
        id="checkbox-group-2"
        v-model="localCheckboxSelected"
        :aria-describedby="ariaDescribedby"
        name="flavour-2"
      >
        <div class="flex flex-col items-center justify-center">
          <b-form-checkbox value="notes"
            >Tracked Cases <span class="font-bold">Without</span> User
            Notes</b-form-checkbox
          >
        </div>
      </b-form-checkbox-group>
    </b-form-group>
  </div>
</template>

<script>
import { BFormCheckboxGroup, BFormCheckbox, BFormGroup } from "bootstrap-vue"
export default {
  components: {
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup
  },
  props: {
    checkboxSelected: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      localCheckboxSelected: this.checkboxSelected // Must be an array reference!
    }
  },
  watch: {
    localCheckboxSelected() {
      this.$emit("updateCheckboxSelected", this.localCheckboxSelected)
    }
  }
}
</script>