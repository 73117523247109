<template>
  <div class="w-full ">
    <div style="padding-top:10px; padding-bottom:15px w-full">
      <div class="flex flex-col items-center w-full">
        <div
          class="flex flex-row items-center justify-center w-full pb-2 space-y-1 border rounded-lg shadow-sm"
        >
          <div class="flex flex-row justify-between w-full max-w-sm mr-1">
            <div v-if="filterBy === 'field'" class="flex flex-col w-full mr-1">
              <div class="flex flex-col">
                <label>Fields </label>
                <div>
                  <b-form-group class="mb-0">
                    <b-input-group size="sm">
                      <b-form-select
                        id="field-by-select"
                        v-model="localFieldBy"
                        :options="fieldOptions"
                      >
                      </b-form-select>
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div v-else class="flex flex-col w-full mr-1">
              <label>Search</label>
              <div>
                <b-form-group class="mb-0">
                  <b-input-group size="sm">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="search"
                      v-model="localSearchFilter"
                      placeholder="Search"
                      debounce="500"
                    />
                  </b-input-group>
                </b-form-group>
              </div>
            </div>
            <div class="flex flex-col w-full">
              <div class="flex flex-col">
                <label>Filter By</label>
                <div>
                  <b-form-group class="mb-0">
                    <b-input-group size="sm">
                      <b-form-select
                        id="search-by-select"
                        v-model="localFilterBy"
                        :options="filterOptions"
                      >
                      </b-form-select>
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row justify-between w-full max-w-sm ">
            <div>
              <label>Notifications From</label>
              <b-input-group
                label="Search By"
                label-for="From-input-mailed-date"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-form-input
                  id="from-input-mailed-date"
                  v-model="localNotificationsDateFrom"
                  size="sm"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  show-decade-nav
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="localNotificationsDateFrom"
                    size="sm"
                    show-decade-nav
                    button-only
                    right
                    locale="en-US"
                    aria-controls="from-input-mailed-date"
                    ><template #button-content>
                      <feather-icon icon="CalendarIcon"/></template
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div>
              <label>Notifications To</label>
              <b-input-group
                label-for="to-input-mailed-date"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-form-input
                  id="to-input-mailed-date"
                  v-model="localNotificationsDateTo"
                  size="sm"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  show-decade-nav
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="localNotificationsDateTo"
                    size="sm"
                    show-decade-nav
                    button-only
                    right
                    locale="en-US"
                    aria-controls="to-input-mailed-date"
                    ><template #button-content>
                      <feather-icon icon="CalendarIcon"/></template
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>

          <div class="flex flex-row w-full max-w-sm px-1">
            <notifications-filter-checkbox
              :checkbox-selected="checkboxSelected"
              @updateCheckboxSelected="updateCheckboxSelected"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import NotificationsFilterCheckbox from "./NotificationsFilterCheckbox"
import {
  BFormDatepicker,
  BFormInput,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect
} from "bootstrap-vue"
export default {
  components: {
    NotificationsFilterCheckbox,
    BFormDatepicker,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormSelect
  },
  props: {
    fieldBy: {
      type: String,
      required: true
    },
    filterBy: {
      type: String,
      required: true
    },
    searchFilter: {
      type: String,
      required: true
    },
    notificationsDateTo: {
      type: String,
      required: true
    },
    notificationsDateFrom: {
      type: String,
      required: true
    },
    checkboxSelected: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      // LOCAL VARIABLES (MUTATEABLE)
      localFieldBy: this.fieldBy,
      localFilterBy: this.filterBy,
      localSearchFilter: this.searchFilter,
      localNotificationsDateTo: this.notificationsDateTo,
      localNotificationsDateFrom: this.notificationsDateFrom,
      localCheckboxSelected: this.checkboxSelected,

      // FILTER BY FIELDS
      fieldOptions: [
        { value: "amount", text: "Amount" },
        { value: "case_status", text: "Case Status" },
        { value: "served_status", text: "Served Status" },
        { value: "is_bankruptcy", text: "Bankruptcy?" },
        { value: "is_garnishment", text: "Garnishment" },
        { value: "garnishment_status", text: "Garnishment Status" },
        { value: "last_pay_date", text: "Last Pay Date" },
        { value: "garnishment_answer", text: "Garnishment Answer" },
        { value: "reference", text: "Reference" },
        { value: "street", text: "Street Address" },
        { value: "city", text: "City" },
        { value: "state", text: "State" },
        { value: "zip_code", text: "Zip" },
        { value: "email", text: "Email" },
        { value: "phone", text: "Phone" },
        { value: "employer_info", text: "Employer" },
        { value: "additional_info", text: "Additional Info" }
      ],
      // FILTER BY SEARCH
      filterOptions: [
        { value: "field", text: "Updated Field" },
        { value: "current", text: "Updated Value" },
        { value: "previous", text: "Previous Value" },
        { value: "defendant", text: "Defendant(s)" },
        { value: "plaintiff", text: "Plaintiff(s)" },
        { value: "county", text: "County" },
        { value: "case_number", text: "Case Number" },
        { value: "all", text: "All Fields" },
        { value: "none", text: "-none-" }
      ]
    }
  },
  watch: {
    // WATCHED LOCAL VARIABLES > UPDATES MUTATES PARENT COMPONENTS FILTERS
    localFieldBy() {
      this.$emit("updateFilters", this.pack())
    },
    localFilterBy() {
      this.$emit("updateFilters", this.pack())
    },
    localSearchFilter() {
      this.$emit("updateFilters", this.pack())
    },
    localNotificationsDateTo() {
      this.$emit("updateFilters", this.pack())
    },
    localNotificationsDateFrom() {
      this.$emit("updateFilters", this.pack())
    }
  },

  methods: {
    updateCheckboxSelected(checkboxData) {
      this.localCheckboxSelected = checkboxData
      this.$emit("updateFilters", this.pack())
    },
    pack() {
      return {
        localFieldBy: this.localFieldBy,
        localFilterBy: this.localFilterBy,
        localSearchFilter: this.localSearchFilter,
        localNotificationsDateTo: this.localNotificationsDateTo,
        localNotificationsDateFrom: this.localNotificationsDateFrom,
        localCheckboxSelected: this.localCheckboxSelected
      }
    }
  }
}
</script>

