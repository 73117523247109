<template>
  <div>
    <b-card class="px-1 mt-5">
      <div class="mb-2">
        <notifications-table-filters
          :field-by="fieldBy"
          :filter-by="filterBy"
          :search-filter="searchFilter"
          :notifications-date-from="notificationsDateFrom"
          :notifications-date-to="notificationsDateTo"
          :checkbox-selected="checkboxSelected"
          @updateFilters="updateFilters"
        ></notifications-table-filters>
      </div>
      <div>
        <notifications-table
          ref="noteTable"
          @packQuery="packageQuery"
        ></notifications-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import NotificationsTable from "./NotificationsTable.vue"
import NotificationsTableFilters from "./NotificationsTableFilters.vue"

import { BCard } from "bootstrap-vue"

export default {
  components: { BCard, NotificationsTable, NotificationsTableFilters },
  data() {
    return {
      // LOCAL VARS TABLE DATA
      tableData: {},
      // LOCAL VARS FOR TABLE FILTERS
      fieldBy: "case_status",
      filterBy: "none",
      searchFilter: "",
      notificationsDateTo: "",
      notificationsDateFrom: "",
      checkboxSelected: ["notes"]
    }
  },
  computed: {},
  watch: {},
  methods: {
    packageQuery(x, callback) {
      let pkg = {
        fieldBy: this.fieldBy,
        filterBy: this.filterBy,
        searchFilter: this.searchFilter,
        notificationsDateTo: this.notificationsDateTo,
        notificationsDateFrom: this.notificationsDateFrom,
        checkboxSelected: this.checkboxSelected
      }
      callback(pkg)
    },
    updateFilters(filterData) {
      this.fieldBy = filterData.localFieldBy
      this.filterBy = filterData.localFilterBy
      this.searchFilter = filterData.localSearchFilter
      this.notificationsDateTo = filterData.localNotificationsDateTo
      this.notificationsDateFrom = filterData.localNotificationsDateFrom
      this.checkboxSelected = filterData.localCheckboxSelected

      this.$refs.noteTable.refreshTable()
    }
  }
}
</script>
