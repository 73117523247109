<template>
  <div>
    <b-modal
      id="modal-center"
      v-model="showModal"
      scrollable
      header-class="bg-white"
      hide-footer
      size="xl"
    >
      <!-- <p class="my-4">{{ modalValue.item }}</p> -->
      <div v-if="showModal">
        <total-case-view :casedata="caseData"> </total-case-view>
      </div>
    </b-modal>
    <div class="relative flex flex-row justify-end w-full">
      <div
        class="
          absolute
          flex flex-row
          p-1
          bg-gray-100
          border
          rounded-lg
          shadow-sm
          -top-12
          right-3
        "
      >
        <div class="flex flex-row mr-2">
          <label class="mr-1"> Per Page </label>
          <b-form-group class="mb-0">
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="mr-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </div>
      </div>
      <transition name="fade">
        <div
          v-if="checkSelect"
          class="
            absolute
            flex flex-col
            w-1/4
            px-1
            pb-1
            bg-gray-300
            border
            rounded-lg
            shadow-md
            border-danger
            -top-12
            left-3
          "
        >
          <div class="flex flex-row font-bold text-md">
            Delete
            {{ allChecked ? "ALL" : selectedRows.length }}
            notifications?
          </div>
          <b-button
            variant="gradient-danger"
            class="shadow-md btn-icon"
            size="sm"
            @click="deleteNotifications()"
          >
            <div class="flex flex-row items-center justify-center">
              <div class="text-xs">YES DELETE</div>
            </div>
          </b-button>
        </div>
      </transition>
    </div>
    <div class="mt-3">
      <b-table
        ref="table"
        class="w-full mb-0 border rounded-lg min-h-6xl"
        no-local-sorting
        show-empty
        selectable
        select-mode="multi"
        responsive
        :items="callAPI"
        :fields="fields"
        :tbody-tr-class="rowClass"
        :per-page="perPage"
        :busy.sync="isBusy"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @row-selected="onRowSelected"
        @filtered="onFiltered"
        @sort-changed="onFiltered"
      >
        <template #head(selected)>
          <div class="flex flex-col items-center justify-center">
            <div class="flex flex-row">
              <div class="invisible">
                <b-badge class="mr-1 text-xs" variant="warning">New</b-badge>
              </div>
              Delete
            </div>
            <div class="flex flex-row">
              <div class="invisible">
                <b-badge class="mr-1 text-xs" variant="warning">New</b-badge>
              </div>
              <b-form-checkbox
                v-model="allChecked"
                :indeterminate.sync="indeterminateChecked"
              >
              </b-form-checkbox>
            </div>
          </div>
        </template>
        <template #cell(selected)="{ item, index, rowSelected }">
          <div class="flex flex-row items-center justify-center">
            <div v-if="item._rowVariant === 'success'">
              <b-badge class="mr-1 text-xs" variant="warning">New</b-badge>
            </div>
            <div v-else class="invisible">
              <b-badge class="mr-1 text-xs" variant="warning">New</b-badge>
            </div>
            <b-form-checkbox
              v-if="rowSelected"
              :checked="true"
              @change="selectCheckbox(index, true)"
            >
            </b-form-checkbox>
            <b-form-checkbox
              v-else
              :checked="false"
              @change="selectCheckbox(index, false)"
            >
            </b-form-checkbox>
          </div>
        </template>
        <template #cell(defendant)="data">
          <div class="flex flex-row">
            <div class="font-bold truncate max-w-xxs">
              {{ data.item.defendant_name }}
            </div>
          </div>
        </template>

        <template #cell(date)="data">
          <div class="flex flex-row font-bold truncate">
            {{ data.item.created_date }}
          </div>
        </template>
        <template #cell(view)="data">
          <b-button
            variant="gradient-secondary"
            class="shadow-md btn-icon"
            size="sm"
            @click="getCaseData(data.item.case)"
          >
            <div class="flex flex-row items-center justify-center">
              <div class="flex flex-row text-xs">View</div>
            </div>
          </b-button>
        </template>
        <template #cell(field)="data">
          <div class="font-bold">{{ data.item.field | capitalize }}</div>
        </template>
        <template #cell(current)="data">
          <div class="flex flex-row bg-gray-100 rounded-sm shadow-sm">
            <div
              class="
                flex flex-row
                items-center
                justify-center
                px-1
                mr-1
                text-gray-200
                rounded-sm
                shadow-md
                bg-success
              "
            >
              <feather-icon icon="ActivityIcon" />
            </div>

            <div class="">{{ data.item.current_value }}</div>
          </div>
        </template>
        <template #cell(previous)="data">
          <div
            class="
              flex flex-row
              items-center
              justify-center
              bg-gray-100
              rounded-sm
              shadow-sm
            "
          >
            {{ data.item.previous_value }}
          </div>
        </template>
        <template #cell(notes)="data">
          <div class="flex flex-row items-center justify-center font-bold">
            <div
              :class="{
                'text-success':
                  data.item._rowVariant === 'success' ? false : true,
              }"
            >
              <feather-icon
                v-if="data.item.has_tracker_notes"
                icon="CheckCircleIcon"
                size="20"
              />
              <feather-icon v-else icon="XCircleIcon" size="20" />
            </div>
          </div>
        </template>
        <template #table-busy>
          <div
            class="
              flex flex-col
              items-center
              justify-center
              my-2
              text-center
              h-72
              text-danger
            "
          >
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import TotalCaseView from "../total-case-view/TotalCaseView.vue";
import {
  BSpinner,
  BFormCheckbox,
  BBadge,
  BTable,
  BButton,
  BFormGroup,
  BFormSelect,
  BPagination,
  BModal,
} from "bootstrap-vue";

export default {
  components: {
    BSpinner,
    TotalCaseView,
    BFormCheckbox,
    BBadge,
    BTable,
    BButton,
    BFormGroup,
    BFormSelect,
    BPagination,
    BModal,
  },
  props: {},
  data() {
    return {
      // SELECTED VARS
      allChecked: false,
      indeterminateChecked: false,
      checkedViaRow: false,
      selectedRows: [],
      // LOCAL VARS FOR TABLE SORTING & PAGINATION
      pageOptions: [5, 7, 10, 15, { value: 100, text: "Show a lot" }],
      perPage: 10,
      isBusy: false,
      currentPage: 1,
      sortDesc: false,
      sortBy: "viewed",
      totalRows: 5,
      // LOCAL VARIABLES
      caseData: [],
      showModal: false,
      tableData: "",

      fields: [
        {
          key: "selected",
        },
        {
          key: "defendant",
          label: "For",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "date",
          label: "Date",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "view",
          label: "View Case",
        },
        {
          key: "field",
          label: "Updated Field",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "current",
          label: "Updated Value",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "previous",
          label: "Previous Value",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "notes",
          label: "User Notes",
          sortable: true,
          sortDirection: "desc",
        },
      ],
    };
  },
  computed: {
    checkSelect() {
      return this.selectedRows.length > 0 ? true : false;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    allChecked(newValue, oldValue) {
      if (newValue) {
        this.$refs.table.selectAllRows();

        return;
      }
      if (!newValue && !this.indeterminateChecked) {
        this.$refs.table.clearSelected();

        return;
      }
    },
  },
  methods: {
    setSelectAll() {
      this.indeterminateChecked = false;
    },
    async onRowSelected(items) {
      if (items.length < 1) {
        await this.setSelectAll();
        this.allChecked = false;
      } else {
        this.indeterminateChecked = true;
      }
      this.selectedRows = items;
    },
    selectCheckbox(index, status) {
      if (status) {
        return this.$refs.table.unselectRow(index);
      }
      return this.$refs.table.selectRow(index);
    },

    refreshTable() {
      this.$refs.table.refresh();
    },
    async callAxios(ctx) {
      let query;
      await this.$emit("packQuery", null, (x) => {
        query = x;
      });
      var x = (ctx.currentPage - 1) * this.perPage;
      if (x < 0) {
        x = x - 1;
      }
      ctx["limit"] = ctx.perPage;
      ctx["offset"] = x;

      query["ctx"] = ctx;

      await this.$AuthAxios.instance
        .get("/api-access/notifications/get-notification-table/", {
          params: {
            filters: query,
          },
        })
        .then((res) => {
          this.tableData = res;
        }) // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          console.log(error);
        });
    },
    onFiltered() {},
    async callAPI(ctx) {
      await this.callAxios(ctx);

      this.totalRows = this.tableData.data.count;

      return this.tableData.data.results;
    },
    rowClass(item, type) {
      if (!item || type !== "row") return "relative";
      if (item._rowVariant === "success") return "relative table-success";
    },
    deleteNotifications() {
      this.$AuthAxios.instance
        .delete("/api-access/notifications/delete/", {
          params: {
            filters: {
              notifications: this.selectedRows,
            },
          },
        }) // eslint-disable-next-line no-unused-vars
        .then((res) => {
          this.$refs.table.refresh();
          this.$GetNotifications(this.$AuthAxios, this.$store);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCaseData(case_number) {
      this.$AuthAxios.instance
        .get("/api-access/search/case-search/", {
          params: {
            single: true,
            caseNumber: case_number,
          },
        })
        .then((res) => {
          this.caseData = res.data.results[0];
          this.showModal = true;
        });
    },
  },
};
</script>
