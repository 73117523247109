<template>
  <div><notifications-table-component /></div>
</template>

<script>
import {} from "bootstrap-vue"
import NotificationsTableComponent from "../components/notifications/NotificationsTableComponent.vue"

export default {
  components: { NotificationsTableComponent },
  data() {
    return {
      contentWidth: this.$store.state.appConfig.layout.contentWidth
    }
  },
  created() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "boxed")
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", this.contentWidth)
  }
}
</script>
